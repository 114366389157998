<template>
    <v-card class="the-alert" elevation="3">
        <v-alert
            v-for="alert in alerts"
            :key="alert.id"
            class="mb-0"
            :type="alert.type"
            dismissible
            dense
            outlined
            text
            transition="scale-transition"
            @input="dismiss(alert)">
            {{ alert.message }}
        </v-alert>
    </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            alerts: s => s.alert.items
        })
    },

    methods: {
        ...mapActions({
            remove: "alert/remove"
        }),
        dismiss(alert) {
            this.remove(alert);
        },
    },
};
</script>

<style lang="scss" scoped>
    .the-alert {
        $background-color: white;
        position: fixed;
        z-index: 300;
        position: absolute;
        left: 0;
        right: 0;
        top: 60px;
        margin: auto;
        width: fit-content;
    }
</style>
